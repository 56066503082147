<template>
  <ion-page>
    <ion-header>

      <ion-toolbar>
        <div slot="start" class="ion-padding-start">
          <img class="h-6" src="/assets/images/wollo-brand-green.svg" />
        </div>
        <ion-buttons slot="end" class="ion-padding-end">
          <div v-if="exchangeRates.USDCentsPerDeSoExchangeRate" class="flex items-center text-sm">
            <img class="deso-xs" src="/assets/images/deso.svg" /> ≈
            {{ utils.displayFiat(exchangeRates.USDCentsPerDeSoExchangeRate) }} USD
          </div>
        </ion-buttons>

        <div class="flex justify-center">
        </div>
      </ion-toolbar>

    </ion-header>

    <ion-content class="ion-padding">
      <div v-if="loading">
        <ion-list>
          <ion-item lines="none">
            <ion-label>Loading</ion-label>
            <ion-spinner slot="start"></ion-spinner>
          </ion-item>
        </ion-list>
      </div>
      <div v-else>
          <nft-grid :nfts="sortedNfts" :showHeader="true" />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { ref, computed, defineComponent } from "vue";
import { useStore } from "vuex";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonItem,
  IonLabel,
  IonList,
  IonSpinner,
  IonToolbar
} from '@ionic/vue';

import { Utils } from "../services/utils";
import NftGrid from "@/components/NftGrid.vue";

export default defineComponent({
  components: { 
    IonButtons,
    IonContent, 
    IonHeader, 
    IonPage, 
    IonItem,
    IonLabel,
    IonList,
    IonSpinner,
    IonToolbar ,

    NftGrid,
  },

  setup() {
    const utils = new Utils();
    const store = useStore();
    const loading = ref(true);

    store.dispatch("loadAccounts").then(function() {
      store.dispatch("loadPendingNfts").then(function() {
        loading.value = false;
      });
    }) 

    const exchangeRates = computed(function () {
      return store.getters.exchangeRates;
    });

    const accounts = computed(function () {
      return store.getters.accounts;
    });

    const pendingNfts = computed(function () {
      return store.getters.pendingNfts;
    });

    const sortedNfts = computed(function () {
      let list = pendingNfts.value;
      list = list.sort((a, b) => b.timestampNanos - a.timestampNanos);
      return list;
    });

    function refresh(event) {
      loading.value = true;
      store.dispatch("loadAccounts").then(function() {
        store.dispatch("loadPendingNfts").then(function() {
          loading.value = false;
          event.target.complete();
        });
      }) 
    }

    return {
      utils: utils,
      loading: loading,

      exchangeRates: exchangeRates,

      accounts: accounts,
      pendingNfts: pendingNfts,
      sortedNfts: sortedNfts,
      refresh: refresh,
    }


  }

});
</script>
